import React, {useEffect, useRef, useState} from 'react'
import useNavigation from '../../hooks/use-navigation'
import SearchSuggestions from './search-suggestions'
import {searchUrlBuilder} from '../../utils/url'
import {useLayoutStateDispatch} from '../../contexts/layout'
import {useIntl} from 'react-intl'
import {getSessionJSONItem, setSessionJSONItem} from '../../utils/utils'
import debounce from 'lodash/debounce'
import Icon from '../icon'
import {RECENT_SEARCH_KEY, RECENT_SEARCH_LIMIT} from '../../constants'
import {getSearchQueryFromURL} from '../../utils/url'
import useSearchSuggestions from '../../hooks/use-search-suggestions'
import useMediaQuery from '../../hooks/use-media-query'

const Search = () => {
	const intl = useIntl()
	const initParamQuery = getSearchQueryFromURL()
	const searchInputRef = useRef()
	const [searchQuery, setSearchQuery] = useState()
	const navigate = useNavigation()
	const recentSearch = getSessionJSONItem(RECENT_SEARCH_KEY)
	const dispatchLayoutState = useLayoutStateDispatch()
	const headerEl = document.getElementsByClassName('header')
	const containerRef = useRef(headerEl?.[0])
	const isDesktop = useMediaQuery('(min-width: 1024px)')
	const searchSuggestions = useSearchSuggestions(searchQuery)
	const touchedEl = useRef(0)

	const toggleSearchVisibility = (state, restoreSearchPhrase) => {
		dispatchLayoutState(state)

		if (restoreSearchPhrase) {
			const refreshedSearchQuery = getSearchQueryFromURL()
			if (refreshedSearchQuery) {
				searchInputRef.current.value = refreshedSearchQuery
			}
		}

		setTimeout(() => {
			setCSSVariables()
			document.body.classList[state ? 'add' : 'remove']('suggestions-visible')
		}, 100)
	}

	const handleHideDropdown = (e) => {
		if (e.key === 'Escape' || e.keyCode === 27) {
			toggleSearchVisibility(false, true)
		}
	}

	const handleClickOutside = (e) => {
		if (
			containerRef?.current &&
			!containerRef?.current?.contains(e.target) &&
			!containerRef?.current?.childrenNodes?.contains(e.target)
		) {
			toggleSearchVisibility(false, true)
		}
	}

	const setCSSVariables = () => {
		const stickyElement = document.querySelectorAll('.js-sticky')
		const headerBarEl = document.querySelectorAll('.header-bar')
		const stickyTopVal =
			stickyElement && stickyElement.length
				? stickyElement[0].getBoundingClientRect().top + 'px'
				: ''
		const headerHeight =
			headerBarEl && headerBarEl.length
				? headerBarEl[0].getBoundingClientRect().top + 'px'
				: ''
		const screenHeightVal = window.visualViewport.height + 'px'

		document.documentElement.style.setProperty('--suggestion-top', stickyTopVal)
		document.documentElement.style.setProperty('--header-bar-height', headerHeight)
		document.documentElement.style.setProperty('--vh', screenHeightVal)
	}

	const handleBlurEvent = () => {
		setTimeout(() => {
			setCSSVariables()
			if (
				touchedEl?.current?.closest('.search-suggestions') ||
				touchedEl?.current?.classList?.contains('prevent-blur') ||
				touchedEl?.current?.classList?.contains('js-sticky')
			) {
				return false
			}

			toggleSearchVisibility(false, isDesktop)
		}, 100)
	}

	const handleResize = () => {
		setTimeout(() => {
			setCSSVariables()
		}, 100)
	}

	useEffect(() => {
		setCSSVariables()
		document.addEventListener('scroll', () => {
			if (!isDesktop) {
				return
			}

			toggleSearchVisibility(false, true)
		})
		document.addEventListener('keydown', handleHideDropdown, true)
		document.addEventListener('click', handleClickOutside, true)
		visualViewport.addEventListener('resize', handleResize)

		return () => {
			document.removeEventListener('scroll', () => {
				if (!isDesktop) {
					return
				}

				toggleSearchVisibility(false, true)
			})
			document.removeEventListener('keydown', handleHideDropdown, true)
			document.removeEventListener('click', handleClickOutside, true)
			visualViewport.removeEventListener('resize', handleResize)
		}
	}, [isDesktop])

	useEffect(() => {
		searchInputRef.current.value = initParamQuery
	}, [initParamQuery])

	useEffect(() => {
		searchInputRef?.current?.addEventListener('blur', handleBlurEvent, true)
		document.addEventListener(
			'mousedown',
			(e) => {
				touchedEl.current = e.target
			},
			true
		)

		return () => {
			searchInputRef?.current?.removeEventListener('blur', handleBlurEvent, true)
			document.removeEventListener(
				'mousedown',
				(e) => {
					touchedEl.current = e.target
				},
				true
			)
		}
	}, [touchedEl?.current])

	const shouldOpenPopover = () => {
		// As per design we only want to show the popover if the input is focused and we have recent searches saved
		// or we have search suggestions available and have inputed some text (empty text in this scenario should show recent searches
		if (document.activeElement.id !== 'search-input') {
			return
		}

		toggleSearchVisibility(true, false)
		debouncedSearch()
	}

	const debouncedSearch = debounce(() => {
		debouncedSearch.cancel()
		const input = searchInputRef?.current?.value
		setSearchQuery(input)
	}, 300)

	const saveRecentSearch = (searchText) => {
		// Get recent searches or an empty array if undefined.
		let searches = getSessionJSONItem(RECENT_SEARCH_KEY) || []

		// Check if term is already in the saved searches
		searches = searches.filter((savedSearchTerm) => {
			return searchText.toLowerCase() !== savedSearchTerm.toLowerCase()
		})

		// Create a new array consisting of the search text and up to 4 other resent searches.
		// I'm assuming the order is newest to oldest.
		searches = [searchText, ...searches].slice(0, RECENT_SEARCH_LIMIT)

		// Replace the save resent search with the updated value.
		setSessionJSONItem(RECENT_SEARCH_KEY, searches)
	}

	const clearInput = () => {
		setSearchQuery('')
		searchInputRef.current.value = ''
		searchInputRef.current.focus()
		setCSSVariables()
	}

	const onSubmitSearch = (e) => {
		e.preventDefault()
		// Avoid blank spaces to be searched
		let searchText = searchInputRef.current.value.trim()
		// Avoid empty string searches
		if (searchText.length < 1) {
			return
		}

		toggleSearchVisibility(false, false)
		saveRecentSearch(searchText)
		navigate(searchUrlBuilder(searchText))

		const event = new Event('searchResultGtmQuery')
		document.dispatchEvent(event)
	}

	const refreshSearchInput = () => {
		document.activeElement.blur()
		const currentParamQuery = getSearchQueryFromURL()

		if (currentParamQuery != searchQuery) {
			setSearchQuery(currentParamQuery)
		}
	}

	const closeAndNavigate = (link) => {
		toggleSearchVisibility(false, false)

		if (!link) {
			clearInput()
		} else {
			navigate(link)
			refreshSearchInput()
		}
	}

	return (
		<>
			<form
				onSubmit={onSubmitSearch}
				className={`search-form ${searchQuery && 'search-form--has-text'}`}
			>
				<input
					autoComplete="off"
					id="search-input"
					enterKeyHint="go"
					onChange={shouldOpenPopover}
					onFocus={shouldOpenPopover}
					onClick={shouldOpenPopover}
					type="search"
					className="search-form__input"
					ref={searchInputRef}
					defaultValue={initParamQuery}
					placeholder={intl.formatMessage({id: 'search.simplesearch.searchtext'})}
				/>

				<span className="search-form__loupe prevent-blur">
					<Icon id="icon-search" />
				</span>
				<span className="search-form__anti-loupe prevent-blur" onClick={clearInput}>
					<Icon id="icon-closethin" />
				</span>
				<span className="search-form__clear prevent-blur" onClick={clearInput}>
					<Icon id="icon-error-cycle" />
				</span>
			</form>

			<span
				className={'header-bar__close link link--underline link--bold prevent-blur'}
				onClick={() => {
					toggleSearchVisibility(false, true)
				}}
			>
				{intl.formatMessage({id: 'locale.global.button.cancel'})}
			</span>

			<SearchSuggestions
				closeAndNavigate={closeAndNavigate}
				recentSearch={recentSearch}
				searchSuggestions={searchSuggestions}
			/>
		</>
	)
}

Search.displayName = 'SearchInput'

export default Search
