import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useCurrency} from '../../hooks/use-currency'
import {formatCurrency} from '../../utils/utils'

const PriceSales = ({priceClass, isPromoPrice, SalesPrice, displayFormattedPrice}) => {
	const intl = useIntl()
	const {currency} = useCurrency()

	const pricingClass = isPromoPrice
		? 'price-' + priceClass + '__sales'
		: 'price-' + priceClass + '__standard'

	const priceValue = SalesPrice

	const {price, maxFractionDigits} = formatCurrency(priceValue, currency)

	const formatPrice = () => {
		const parts = new Intl.NumberFormat(intl.locale, {
			style: 'currency',
			currency: currency,
			useGrouping: true
		}).formatToParts(price)

		const currencySymbol = parts.find((part) => part.type === 'currency')
		const integer = parts
			.filter((part) => part.type === 'integer')
			.map((part) => part.value)
			.join(' ')
		const fraction = parts.find((part) => part.type === 'fraction')

		if (currency == 'CZK') {
			return (
				<span>
					<span className={'price-' + priceClass + '__amount'}>{integer}</span>
					<span className={'price-' + priceClass + '__suffix'}>
						{' ' + currencySymbol.value}
					</span>
				</span>
			)
		} else {
			return (
				<React.Fragment>
					<span className={'price-' + priceClass + '__amount'}>{integer}</span>
					<span className={'price-' + priceClass + '__currency'}>
						<span>{fraction.value}</span>
						<span>{currencySymbol.value}</span>
					</span>
				</React.Fragment>
			)
		}
	}

	return (
		<div className={pricingClass}>
			{price >= 0 ? (
				displayFormattedPrice ? (
					formatPrice()
				) : (
					<div className={'price-' + priceClass + '__amount'}>
						{intl.formatNumber(price, {
							style: 'currency',
							currency: currency,
							maximumFractionDigits: maxFractionDigits,
							useGrouping: true
						})}
					</div>
				)
			) : (
				<div className={'price-' + priceClass + '__amount'}>
					{intl.formatMessage({id: 'product.product.pricing.noprice'})}
				</div>
			)}
		</div>
	)
}

PriceSales.propTypes = {
	priceClass: PropTypes.string,
	SalesPrice: PropTypes.number,
	isPromoPrice: PropTypes.bool,
	displayFormattedPrice: PropTypes.bool
}

export default PriceSales
